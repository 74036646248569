import * as React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import { Home } from '../pages/Home';
import { Creators } from '../pages/Creators';
import { Galeria } from '../pages/Galeria';
import { Omnivideo } from '../pages/Omnivideo';
import { Termos } from '../pages/Termos';
import { Politica } from '../pages/Politica';

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route path={'/'} element={<Home />} />
      <Route path={'/galeria'} element={<Galeria />} />
      <Route path={'/omnivideo'} element={<Omnivideo />} />
      <Route path={'/creators'} element={<Creators />} />
      <Route path={'/termos-de-uso'} element={<Termos isFullPage={true} />} />
      <Route path={'/politica-de-privacidade'} element={<Politica />} />
    </RouterRoutes>
  );
};