import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

export const Header = ({ onScrollToBrands }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goToCreators = () => navigate('/creators');
  const goToHome = () => navigate('/');

  const goToFinalLevel = () => window.open('https://finallevel.com.br/', '_blank');

  const goToLogin = () => window.open('https://creator.omnicontent.com.br/', '_blank');

  const drawer = (
    <Box
      sx={{ width: 250, backgroundColor: 'var(--primary-dark-color)', height: '100%', color: 'var(--white-color)' }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        <ListItem button onClick={goToHome}>
          <ListItemText primary="Para marcas" />
        </ListItem>
        <ListItem button onClick={goToCreators}>
          <ListItemText primary="Para creators" />
        </ListItem>
        <ListItem button onClick={goToFinalLevel}>
          <ListItemText primary="Final Level Co" />
        </ListItem>
      </List>
      <Divider sx={{ borderColor: 'var(--white-color)', my: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: '10px 20px',
        }}
      >
        <Button
          startIcon={<PersonIcon />}
          sx={{
            color: 'var(--white-color)',
            textTransform: 'none',
            borderRadius: '50px',
            padding: '5px 15px',
            minWidth: 'auto',
            backgroundColor: 'var(--primary-light-color)',
          }}
          onClick={goToLogin}
        >
          login
        </Button>
        <Button
          sx={{
            color: 'var(--primary-dark-color)',
            backgroundColor: 'var(--white-color)',
            textTransform: 'none',
            borderRadius: '50px',
            padding: '5px 15px',
            minWidth: 'auto',
            fontWeight: 'bold',
          }}
        >
          cadastre-se
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'var(--primary-dark-color)',
          padding: { xs: '10px 0', md: '10px 20px' },
          boxShadow: 'none',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <img src={logo} alt="logo" style={{ maxWidth: '188px' }} />
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3, fontWeight: 'bold' }}>
              {/* <Button color="inherit" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={goToHome}>
                Home
              </Button> */}
              <Button color="inherit" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={goToHome}>
                Para marcas
              </Button>
              <Button color="inherit" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={goToCreators}>
                Para creators
              </Button>
              <Button color="inherit" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={goToFinalLevel}>
                Final Level Co
              </Button>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  borderRadius: '50px',
                  backgroundColor: 'var(--primary-light-color)',
                  padding: '5px 10px',
                }}
              >
                <Button
                  startIcon={<PersonIcon />}
                  sx={{
                    color: 'var(--white-color)',
                    textTransform: 'none',
                    borderRadius: '50px',
                    padding: '5px 15px',
                    minWidth: 'auto',
                  }}
                  onClick={goToLogin}
                >
                  login
                </Button>
                <Button
                  sx={{
                    color: 'var(--primary-dark-color)',
                    backgroundColor: 'var(--white-color)',
                    textTransform: 'none',
                    borderRadius: '50px',
                    padding: '5px 15px',
                    minWidth: 'auto',
                    fontWeight: 'bold',
                  }}
                  onClick={goToCreators}
                >
                  cadastre-se
                </Button>
              </Box>
            </Box>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: 'block', md: 'none' } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>
    </>
  );
};
