import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';

export const Gallery = ( {titulo, cor, placeholdername, data_feed_id, data_auth} ) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://omnifeed.omnicontent.com.br/omnifeed.js";
    // script.src = "http://127.0.0.1:8080/dist/omnifeed.js";
    script.charset = "utf-8";
    script.setAttribute('data-feed-id', data_feed_id);
    script.setAttribute('data-auth', data_auth);
    script.setAttribute('data-inline-maxsize', "w-250");
    script.setAttribute('data-inline-placeholder', placeholdername);
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, [data_feed_id, data_auth, placeholdername]); // Added missing dependencies

  return (
    <Box
      sx={{
        backgroundColor: cor,
        // backgroundColor: 'var(--primary-light-color)',
        padding: '50px 0',
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="h2">
        {titulo}
        </Typography>
        <div id={placeholdername}></div>
      </Container>
    </Box>
  );
};
