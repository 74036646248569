// Importando todas as imagens de marcas
import bgtPactual from '../assets/brands/bgtPactual.webp';
import google from '../assets/brands/google.webp';
import mercadoLivreLogo from '../assets/brands/mercado-livre-logo-8.webp';
import gerandoFalcoes from '../assets/brands/GerandoFalcoes.webp';
import bancoDoBrasil from '../assets/brands/banco_do_brasil.webp';
import accor from '../assets/brands/accor.webp';
import meta from '../assets/brands/meta.webp';
import oi from '../assets/brands/Oi.webp';
import cocaCola from '../assets/brands/coca-cola.webp';
import opera from '../assets/brands/Opera.webp';
import mercadoLivre from '../assets/brands/MercadoLivre.webp';
import disney from '../assets/brands/Disney.webp';
import universal from '../assets/brands/Universal.webp';
import epicGames from '../assets/brands/EpicGames.webp';
import tencent from '../assets/brands/Tencent.webp';
import premioEsports from '../assets/brands/Premio-e-Sports.webp';
import claro from '../assets/brands/Claro.webp';
import cbf from '../assets/brands/CBF.webp';
import nivea from '../assets/brands/nivea.webp';
import kwai from '../assets/brands/Kwai.webp';
import leagueOfLegends from '../assets/brands/League_of_Legends_Wild_Rift.webp';
import estacio from '../assets/brands/Estacio.webp';
import youtube from '../assets/brands/Youtube.webp';
import rivelry from '../assets/brands/Rivelry.webp';
import monster from '../assets/brands/Monster.webp';
import picpay from '../assets/brands/picpay.webp';
import ifood from '../assets/brands/Ifood.webp';
import bradesco from '../assets/brands/Bradesco.webp';
import santander from '../assets/brands/Santander.webp';
import tiktok from '../assets/brands/Tiktok.webp';
import chinaInBox from '../assets/brands/China-in-Box.webp';
import spaten from '../assets/brands/spaten.webp';
import samsung from '../assets/brands/samsung.webp';
import globo from '../assets/brands/Globo.webp';
import electrolux from '../assets/brands/Electrolux.webp';
import riotGames from '../assets/brands/RiotGames.webp';
import cruzeiroDoSul from '../assets/brands/cruzeiro_do_sul.webp';

export const brands = [
  { name: 'BTG Pactual', logo: bgtPactual },
  // { name: 'Google', logo: google },
  { name: 'Mercado Livre', logo: mercadoLivreLogo },
  { name: 'Gerando Falcões', logo: gerandoFalcoes },
  { name: 'Banco do Brasil', logo: bancoDoBrasil },
  { name: 'Accor', logo: accor },
  // { name: 'Meta', logo: meta },
  { name: 'Oi', logo: oi },
  { name: 'Coca-Cola', logo: cocaCola },
  { name: 'Opera', logo: opera },
  { name: 'Mercado Livre', logo: mercadoLivre },
  { name: 'Disney', logo: disney },
  { name: 'Universal', logo: universal },
  { name: 'Epic Games', logo: epicGames },
  { name: 'Tencent', logo: tencent },
  { name: 'Prêmio e-Sports', logo: premioEsports },
  { name: 'Claro', logo: claro },
  { name: 'CBF', logo: cbf },
  { name: 'Nivea', logo: nivea },
  // { name: 'Kwai', logo: kwai },
  { name: 'League of Legends', logo: leagueOfLegends },
  { name: 'Estácio', logo: estacio },
  // { name: 'YouTube', logo: youtube },
  { name: 'Rivelry', logo: rivelry },
  { name: 'Monster', logo: monster },
  { name: 'PicPay', logo: picpay },
  { name: 'iFood', logo: ifood },
  { name: 'Bradesco', logo: bradesco },
  { name: 'Santander', logo: santander },
  // { name: 'TikTok', logo: tiktok },
  { name: 'China in Box', logo: chinaInBox },
  { name: 'Spaten', logo: spaten },
  { name: 'Samsung', logo: samsung },
  { name: 'Globo', logo: globo },
  { name: 'Electrolux', logo: electrolux },
  { name: 'Riot Games', logo: riotGames },
  { name: 'Cruzeiro do Sul', logo: cruzeiroDoSul },
];
