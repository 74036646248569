import React, { useRef } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const Termos = ({ isFullPage }) => {
  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>
        {isFullPage &&
          <Header />
        }
        <Container maxWidth="md">
      <Box mt={5} mb={5}>
        <Typography variant="h4" gutterBottom>
          Termos de Uso da Plataforma Omnicontent
        </Typography>
        {/* <Typography variant="subtitle1" gutterBottom>
          Data de Última Atualização: DD/MM/AAAA
        </Typography> */}

        {/* Seção 1 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            1. Aceitação dos Termos
          </Typography>
          <Typography paragraph>
            Ao se cadastrar e utilizar a plataforma Omnicontent, você, criador de conteúdo (doravante “Creator”), 
            concorda em cumprir os termos e condições descritos neste documento. O uso da plataforma está sujeito a estes 
            Termos de Uso, que podem ser atualizados periodicamente.
          </Typography>
        </Box>

        {/* Seção 2 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            2. Cadastro e Conta de Usuário
          </Typography>
          <Typography paragraph>
            2.1. Para utilizar a plataforma, o Creator deve fornecer informações verdadeiras e atualizadas no ato do cadastro.
          </Typography>
          <Typography paragraph>
            2.2. O Creator é responsável pela segurança de sua conta e deve manter suas credenciais de acesso confidenciais.
          </Typography>
          <Typography paragraph>
            2.3. Qualquer atividade realizada por meio da conta do Creator será de sua responsabilidade.
          </Typography>
        </Box>

        {/* Seção 3 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            3. Uso da Plataforma
          </Typography>
          <Typography paragraph>
            3.1. A plataforma Omnicontent é fornecida com o objetivo de facilitar a criação e entrega de conteúdo para campanhas e clientes associados.
          </Typography>
          <Typography paragraph>
            3.2. O Creator concorda em utilizar a plataforma de forma adequada, respeitando as diretrizes de uso e os direitos de outros usuários.
          </Typography>
          <Typography paragraph>
            3.3. O uso da plataforma para qualquer atividade ilegal ou que infrinja direitos de terceiros é estritamente proibido.
          </Typography>
        </Box>

        {/* Seção 4 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            4. Propriedade Intelectual
          </Typography>
          <Typography paragraph>
            4.1. O Creator reconhece que a Omnicontent e seus parceiros detêm todos os direitos de propriedade intelectual relacionados à plataforma, 
            incluindo software, design, marcas e funcionalidades.
          </Typography>
          <Typography paragraph>
            4.2. O conteúdo produzido pelo Creator em campanhas específicas poderá ser utilizado pela Omnicontent e pelo cliente da campanha, 
            conforme estabelecido no contrato de cada projeto.
          </Typography>
        </Box>

        {/* Seção 5 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            5. Entregas e Direitos sobre o Conteúdo
          </Typography>
          <Typography paragraph>
            5.1. Ao enviar conteúdo por meio da plataforma, o Creator concede à Omnicontent e seus clientes o direito de uso, exibição, distribuição e modificação desse conteúdo, conforme especificado em cada campanha.
          </Typography>
          <Typography paragraph>
            5.2. O Creator garante que o conteúdo entregue não viola direitos autorais, marcas, patentes ou qualquer outro direito de propriedade de terceiros.
          </Typography>
          <Typography paragraph>
            5.3. O Creator concorda que o conteúdo poderá ser submetido a análises de qualidade e moderação conforme as regras da campanha.
          </Typography>
        </Box>

        {/* Seção 6 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            6. Pagamentos e Remuneração
          </Typography>
          <Typography paragraph>
            6.1. O Creator será remunerado conforme os termos específicos de cada campanha ou projeto em que estiver envolvido.
          </Typography>
          <Typography paragraph>
            6.2. Os pagamentos serão processados após a aprovação das entregas e conforme os prazos estipulados.
          </Typography>
          <Typography paragraph>
            6.3. O Creator deve fornecer dados bancários precisos para o processamento correto dos pagamentos.
          </Typography>
        </Box>

        {/* Seção 7 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            7. Cancelamento de Conta
          </Typography>
          <Typography paragraph>
            7.1. A Omnicontent reserva-se o direito de suspender ou encerrar o acesso à plataforma caso o Creator viole estes Termos de Uso ou pratique condutas que prejudiquem o bom funcionamento da plataforma.
          </Typography>
          <Typography paragraph>
            7.2. O Creator também pode solicitar o cancelamento de sua conta a qualquer momento, cessando o uso da plataforma.
          </Typography>
        </Box>

        {/* Seção 8 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            8. Limitação de Responsabilidade
          </Typography>
          <Typography paragraph>
            8.1. A Omnicontent não se responsabiliza por quaisquer danos indiretos, incidentais ou consequenciais decorrentes do uso ou da incapacidade de uso da plataforma.
          </Typography>
          <Typography paragraph>
            8.2. A plataforma pode estar sujeita a interrupções temporárias devido a atualizações ou falhas técnicas. A Omnicontent se compromete a restaurar o funcionamento o mais rápido possível, mas não garante a disponibilidade ininterrupta do serviço.
          </Typography>
        </Box>

        {/* Seção 9 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            9. Alterações nos Termos
          </Typography>
          <Typography paragraph>
            9.1. A Omnicontent reserva-se o direito de alterar ou atualizar estes Termos de Uso a qualquer momento. O Creator será notificado sobre mudanças significativas e, ao continuar a usar a plataforma, aceita os novos termos.
          </Typography>
        </Box>

        {/* Seção 10 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            10. Disposições Gerais
          </Typography>
          <Typography paragraph>
            10.1. Estes Termos de Uso constituem o acordo integral entre o Creator e a Omnicontent, regendo o uso da plataforma.
          </Typography>
          <Typography paragraph>
            10.2. Qualquer conflito relacionado a estes Termos será resolvido de acordo com as leis [jurisdição aplicável].
          </Typography>
        </Box>

        {/* Botão de Aceitação
        <Box mt={5} mb={5} textAlign="center">
          <Button variant="contained" color="primary">
            Aceitar os Termos de Uso
          </Button>
        </Box> */}
      </Box>
    </Container>
    {isFullPage &&
        <Footer />
    }
      </Box>
    </>
  );
};
