import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ConversionOptimizer = ({ onScrollToForm, isHome = false }) => {
  const navigate = useNavigate();

  const goToForm = () => {
    if (isHome) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate('/creators');
    } else {
      onScrollToForm();
    }
  };

  const handleContato = () => {
    const email = "omnicontent@finallevel.com.br";
    const subject = "[SITE OMNICONTENT] Marcas - Quero agendar uma demonstração";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'var(--primary-light-color)',
        padding: '50px 0',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        maxWidth="xl"
      >
        {/* <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.5rem', md: '5rem' },
            marginBottom: '20px',
          }}
        >
          AUMENTE A SUA CONVERSÃO EM VENDAS
        </Typography> */}

        <div>
          <iframe
            style={{ maxWidth: '90%' }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ztjQj5QE6Jw"
            title="Omnicontent"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </div>


        {/* <Typography
          variant="body1"
          sx={{
            marginBottom: '40px',
            fontSize: { xs: '0.875rem', md: '1rem' },
          }}
        >
          Transformamos ideias em vídeos reais, criando conexões genuínas entre marcas e seus públicos.
        </Typography> */}

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '20px',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            onClick={handleContato}
            sx={{
              backgroundColor: '#D1FF00',
              color: 'var(--primary-dark-color)',
              padding: '10px 20px',
              fontWeight: 'bold',
              borderRadius: 50,
              fontFamily: 'var(--Zumee400)',
              width: { xs: '100%', md: 'auto' },
              '&:hover': {
                backgroundColor: '#BCE500',
              },
            }}
          >
            MARCAS: AGENDAR UMA DEMONSTRAÇÃO
          </Button>
        </Box> */}
      </Container>
    </Box>
  );
};
