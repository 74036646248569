import { Box } from '@mui/material';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Gallery } from '../../components/Gallery';
// import { WelcomeFormSection } from '../../components/WelcomeFormSection';

export const Galeria = () => {
  return (
    <>
      <Box sx={{ color: 'var(--white-color)', minHeight: '100vh' }}>
        <Header isHome />
        {/* <Box>
          <WelcomeFormSection showImage />
        </Box> */}
        <Gallery
          titulo='NIVEA'
          cor='var(--primary-light-color)'
          placeholdername='omnifeed-placeholder-1'
          data_feed_id="5"
          data_auth="1"
        />
        <Gallery
          titulo='Méqui'
          cor='secondary.main'
          placeholdername='omnifeed-placeholder-2'
          data_feed_id="6"
          data_auth="5"
        />
        <Gallery
          titulo='Riot Games'
          cor='var(--primary-light-color)'
          placeholdername='omnifeed-placeholder-3'
          data_feed_id="7"
          data_auth="6"
        />
        <Footer />
      </Box>
    </>
  );
};
