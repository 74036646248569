import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import ucgGif from '../../assets/animations/omni.gif';

export const UGCSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--primary-light-color)',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 3,
        }}
        maxWidth="xl"
      >
        <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, mb: { xs: 4, md: 0 } }}>
          <Box
            sx={{
              padding: '0px 16px',
              borderRadius: '50px',
              display: 'inline-block',
              mb: 2,
              border: '2px solid white',
            }}
          >
            <Typography variant="subtitle1" sx={{ color: 'var(--text-primary-color)', fontSize: '1.4rem' }}>
            CONTEÚDO OMNICHANNEL
            </Typography>
          </Box>
          <Typography
            variant="h1"
            sx={{
              lineHeight: '1',
              marginBottom: '20px',
              fontSize: { xs: '2rem', md: '5rem' },
            }}
          >
            O <span style={{ textDecoration: 'line-through', fontWeight: 'inherit', fontFamily: 'inherit' }}>FUTURO</span> PRESENTE DA COMUNICAÇÃO É O VÍDEO
          </Typography>
          {/* <Typography variant="body1" sx={{ marginBottom: '30px' }}>
            Transformamos ideias em vídeos reais, criando conexões genuínas entre marcas e seus públicos.
          </Typography> */}
        </Box>
        <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, pr: 3 }}>
          <img src={ucgGif} alt="Influencer images" style={{ width: '100%', borderRadius: '20px' }} />
        </Box>
      </Container>
    </Box>
  );
};
