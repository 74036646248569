import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { WelcomeFormSection } from '../../components/WelcomeFormSection';
import { VideoSection } from '../../components/VideoSection';
import { UGCSection } from '../../components/UGCSection';
import { BrandsSection } from '../../components/BrandsSection';
import { ConversionOptimizer } from '../../components/ConversionOptimizer';

export const Home = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://omnifeed.omnicontent.com.br/omnifeed.js";
    // script.src = "http://127.0.0.1:8080/dist/omnifeed.js";
    script.charset = "utf-8";
    script.setAttribute('data-feed-id', "8");
    script.setAttribute('data-auth', "4");
    script.setAttribute('data-inline-maxsize', "w-250");
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const brandsSectionRef = useRef(null);
  const ugcSectionRef = useRef(null);
  const formSectionRef = useRef(null);

  const scrollToBrands = () => {
    if (brandsSectionRef.current) {
      brandsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToUGC = () => {
    if (ugcSectionRef.current) {
      ugcSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToForm = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box sx={{ color: 'var(--white-color)', minHeight: '100vh' }}>
        <Header onScrollToBrands={scrollToBrands} scrollToUGC={scrollToUGC} isHome />
        <Box ref={formSectionRef}>
          <WelcomeFormSection showImage />
        </Box>
        <ConversionOptimizer isHome />
        <VideoSection />
        <Box ref={ugcSectionRef}>
          <UGCSection />
        </Box>
        <Box ref={brandsSectionRef}>
          <BrandsSection />
        </Box>
        <Footer />
      </Box>
    </>
  );
};
