import React, { useState } from 'react';
import { Box, FormControlLabel, TextField, Checkbox, Link, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import { Get, Post } from '../../http/httpVerbs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Termos } from '../../pages/Termos';

export const SignUpCreatorForm = () => {
  const primaryColor = 'var(--primary-color)';
  const primaryLightColor = 'var(--primary-light-color)';

  const [form, setForm] = useState();
  const [isAccepted, setIsAccepted] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAccepted(isChecked);

    setForm((prevForm) => ({
      ...prevForm,
      termos: isChecked,
    }));
  };

  const submitFormData = async () => {
    try {
      const url = `https://backend.omnicontent.com.br/creators`;

      const creator = await Get(`${url}?query=${form.email}`);
      const email = form.email;
      const validade = new Date(Date.now() + 5 * 60 * 1000).toISOString();
      const data = `${email}|${validade}`;
      const hash = btoa(data);

      if (creator && creator[0]?.id) {
        toast.error('Este e-mail já está cadastrado. Para acessar a plataforma, clique em Login!', {
          position: 'top-center',
          autoClose: 5000,
        });
      } else {
        await Post(url, form);

        toast.success('Cadastro efetuado com sucesso!', {
          position: 'top-center',
          autoClose: 5000,
        });

        setTimeout(() => {
          window.location = `https://creator.omnicontent.com.br/?hash=${hash}`;
        }, 5000);
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o formulário.', {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitFormData();
  };

  const onFieldChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      origem: 'landing-page',
      [name]: value,
    });
  };
  return (
    <><Box
      sx={{
        maxWidth: { xs: '100%', md: '50%' },
        backgroundColor: primaryColor,
        padding: '40px',
        borderRadius: '15px',
        width: { xs: '80%', md: '30%' },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          lineHeight: '1',
          marginBottom: '30px',
          fontSize: { xs: '2rem', md: '1.7rem' },
          color: 'var(--white-color)',
          textAlign: 'center',
        }}
      >
        Cadastro de Creator
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <TextField
          label="Nome"
          name="nome"
          variant="outlined"
          onChange={onFieldChange}
          sx={{
            marginBottom: '20px',
            input: { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
              '&:hover fieldset': {
                borderColor: primaryLightColor,
              },
            },
            '& label': { color: primaryLightColor },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
            },
          }}
        />
        <TextField
          label="E-mail"
          name="email"
          variant="outlined"
          onChange={onFieldChange}
          sx={{
            marginBottom: '20px',
            input: { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
              '&:hover fieldset': {
                borderColor: primaryLightColor,
              },
            },
            '& label': { color: primaryLightColor },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
            },
          }}
        />
        <TextField
          label="Senha"
          type="password"
          variant="outlined"
          name="senha"
          onChange={onFieldChange}
          sx={{
            marginBottom: '20px',
            input: { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
              '&:hover fieldset': {
                borderColor: primaryLightColor,
              },
            },
            '& label': { color: primaryLightColor },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& fieldset': {
                borderColor: primaryLightColor,
              },
            },
          }}
        />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAccepted}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: primaryLightColor,
                    '&.Mui-checked': { color: primaryLightColor },
                  }}
                />
              }
              label={
                <Typography sx={{ color: 'white' }}>
                  Eu aceito os{' '}
                  <Link
                    // onClick={handleClickOpen}
                    underline="always"
                    sx={{ color: primaryLightColor }}
                  >Termos e Condições
                  </Link>
                </Typography>
              }
              sx={{ color: 'white', marginBottom: '20px' }}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={!isAccepted}
              sx={{
                backgroundColor: primaryLightColor,
                color: 'white',
                padding: '10px 20px',
                fontWeight: 'bold',
                borderRadius: '50px',
                marginTop: '20px',
              }}
            >
              Cadastrar
            </Button>
      </Box>

      <Typography variant="body2" sx={{ marginTop: '20px', color: 'white' }}>
        Já tem cadastro? Então{' '}
        <Link
          href="https://creator.omnicontent.com.br/"
          sx={{
            color: primaryLightColor,
            textDecoration: 'none',
          }}
        >
          faça seu login por aqui
        </Link>
      </Typography>
    </Box>

      {/* Modal - Dialog for Terms and Conditions */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Omnicontent</DialogTitle>
        <DialogContent>
          <Termos />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      </>
  );
};
