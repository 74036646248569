import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';

export const VideoSection = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://omnifeed.omnicontent.com.br/omnifeed.js";
    // script.src = "http://127.0.0.1:8080/dist/omnifeed.js";
    script.charset = "utf-8";
    script.setAttribute('data-feed-id', "4");
    script.setAttribute('data-auth', "4");
    script.setAttribute('data-inline-maxsize', "w-250");
    script.setAttribute('data-inline-placeholder', "omnifeed-placeholder");
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box component="section" sx={{ py: 8, backgroundColor: 'secondary.main' }}>
      <Container maxWidth="xl">
        {/* <Typography variant="body1" sx={{ mb: 2, fontSize: '1.6rem' }}>
          Encurte a jornada da descoberta até a compra
        </Typography> */}
        <Typography variant="h2" sx={{ mb: 6 }}>
        DA DESCOBERTA ATÉ A COMPRA, ESSA JORNADA PODE SER ENCURTADA
        </Typography>
        <div id="omnifeed-placeholder"></div>
      </Container>
    </Box>
  );
};

export default VideoSection;
