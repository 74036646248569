import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { SignUpCreatorForm } from '../SignUpCreatorForm';
import 'react-toastify/dist/ReactToastify.css';
import ImageSection from '../../assets/images/imageSection.png';
import { useNavigate } from 'react-router-dom';

export const WelcomeFormSection = ({ showImage = false }) => {
  const navigate = useNavigate();
  const goToCreators = () => navigate('/creators');
  return (
    <Box
      sx={{
        backgroundColor: 'var(--primary-dark-color)',
        padding: showImage ? 0 : '80px 0',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          py: { xs: 3, md: 1 },
        }}
        maxWidth="lg"
      >
        <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, mb: { xs: 4, md: 0 } }}>
          <Typography
            variant="h1"
            sx={{
              lineHeight: '1',
              marginBottom: '20px',
              fontSize: { xs: '2rem', md: '5rem' },
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            O VIDEO CERTO PARA PARA CADA PONTO DE CONTATO NA JORNADA DE COMPRA
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '30px',
              fontSize: { xs: '1rem', md: '1.25rem' },
              color: 'white',
            }}
          >
            OmniContent é a plataforma para marcas que buscam <br />
            impactar seus públicos com vídeos personalizados, <br />
            otimizados para cada etapa da jornada do consumidor
          </Typography>
          {showImage && (
            <>
              <Button
                variant="contained"
                component="a"
                href="https://calendly.com/final-level-co/omnicontent"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  backgroundColor: '#D1FF00',
                  color: 'var(--primary-dark-color)',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  borderRadius: 50,
                  fontFamily: 'var(--Zumee400)',
                  width: { xs: '100%', md: 'auto' },
                  '&:hover': {
                    backgroundColor: '#BCE500',
                  },
                }}
              >
                Sou uma marca e quero agendar uma demonstração
              </Button>
              <Button
                variant="contained"
                onClick={goToCreators}
                sx={{
                  backgroundColor: '#c178fc',
                  color: 'var(--primary-dark-color)',
                  padding: '10px 20px',
                  margin: '10px 0',
                  fontWeight: 'bold',
                  borderRadius: 50,
                  fontFamily: 'var(--Zumee400)',
                  width: { xs: '100%', md: 'auto' },
                  '&:hover': {
                    backgroundColor: '#450754',
                    color: 'white',
                  },
                }}
              >
                SOU CREATOR E QUERO ME CADASTRAR
              </Button>
            </>
          )}
        </Box>
        {showImage ? (
          <Box
            component="img"
            src={ImageSection}
            alt="Seção de Imagem"
            sx={{
              maxWidth: { xs: '100%', md: '50%' },
              objectFit: 'contain',
            }}
          />
        ) : (
          <SignUpCreatorForm />
        )}
      </Container>
    </Box>
  );
};
