import React, { useRef } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const Politica = () => {
  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>
        <Header />
        <Container maxWidth="md">
      <Box mt={5} mb={5}>
        <Typography variant="h4" gutterBottom>
          Política de privacidade Omnicontent
        </Typography>
        {/* Seção 1 */}
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            1. Aceitação dos Termos
          </Typography>
          <Typography paragraph>
            Ao se cadastrar e utilizar a plataforma Omnicontent, você, criador de conteúdo (doravante “Creator”), 
            concorda em cumprir os termos e condições descritos neste documento. O uso da plataforma está sujeito a estes 
            Termos de Uso, que podem ser atualizados periodicamente.
          </Typography>
        </Box>
      </Box>
    </Container>

        <Footer />
      </Box>
    </>
  );
};
